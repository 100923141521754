.ant-form-item {
  margin-bottom: 30px;
}
.ant-form-item-row {
  display: flex;
  align-items: flex-start;
}
.ant-form-item-row .ant-form-item-label {
  height: 46px;
}
.ant-form-item-required {
  margin-right: 36px;
  width: 166px;
  height: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #1c222b;
}
.ant-form-item-label {
  margin-right: 36px;
  width: 166px;
  height: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #1c222b;
}

@media (max-width: 600px) {
  .ant-form-item {
    margin-bottom: 15px;
  }
}
