.ant-pagination-item-active {
  border: none !important;
}

.ant-pagination-item-active button {
  background-color: #3d4a5c;
  color: white;
}

.ant-popover .ant-popover-arrow {
  display: none;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: #f9fafb;
  padding: 12px 14px;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 12px 14px;
}

.ant-table-wrapper .ant-table-tbody > tr > td .ant-btn:hover path {
  stroke: white;
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f2f5ff;
}

.ant-modal .ant-modal-content .ant-modal-confirm-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.ant-modal .ant-modal-content .ant-modal-confirm-body {
  position: relative;
}

.ant-modal .ant-modal-content .ant-modal-confirm-body svg {
  display: block;
  margin-bottom: 10px;
}

.btn-action:hover svg path {
  stroke: white;
}

.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 20px;
  font-weight: 600;
}

.ant-modal .ant-modal-content .ant-modal-confirm-body .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  cursor: pointer;
}

.ant-popover .ant-popover-inner {
  padding: 8px;
}

.ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.ant-modal .ant-modal-content .ant-modal-confirm-btns .ant-btn {
  width: 125px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d7dfe9;
  border-radius: 4px;
  box-shadow: none;
}

.ant-table-wrapper table tr td.ant-table-selection-column {
  vertical-align: top;
}

.ant-tree-select-dropdown {
  max-width: 100%;
}
