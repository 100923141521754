.switch-item {
  display: inline-flex;

  .ant-form-item-row {
    gap: 10px;
    flex-wrap: nowrap;
    width: auto;
  }

  .ant-form-item-label {
    width: auto;
    height: unset;
    margin: 0;
    order: 2;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #2d3643;
  }

  .ant-switch {
    background: #d7dfe9;

    &.ant-switch-checked {
      background: #1b4dff;
    }
  }
}

@media (max-width: 600px) {
  .switch-item {
    width: 100%;

    .ant-form-item {
      width: 100%;
    }

    .ant-form-item-label {
      flex: 1 !important;
    }

    .ant-form-item-control {
      flex: unset !important;
    }
  }
}
