.department-list .department-item-container {
  display: flex;
  flex-direction: row;
}
.department-list .department-item-container .position-form-item .ant-form-item-label {
  width: unset;
  margin-right: 6px;
  margin-left: 28px;
}

.department-list .custom-tree-department.ant-tree-select {
  box-sizing: border-box;
  background: #f9fafb;
  border-bottom: 1px solid #afbaca;
}
.department-list .custom-tree-department .ant-select-selector {
  border: none !important;
  width: 100%;
  background: transparent !important;
}
.department-list .custom-select-position.ant-select {
  box-sizing: border-box;
  height: 46px;
  background: #f9fafb;
  border-bottom: 1px solid #afbaca;
}
.department-list .custom-select-position .ant-select-selector {
  border: none;
  background: transparent;
}

@media (max-width: 600px) {
  .department-list .department-item-container {
    flex-direction: column;
  }

  .ant-form-item-row .ant-form-item-label {
    padding: 0;
  }

  .department-list .department-item-container .position-form-item .ant-form-item-label {
    margin: 0;
  }
}
