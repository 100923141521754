.ant-select-selector {
  width: 100%;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: #f9fafb !important;
}
.ant-select-selector-search {
  width: 100%;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: #f9fafb !important;
}
.custom-tree-store .ant-tree-select {
  box-sizing: "border-box";
  padding: 0;
  height: 46px;
  background: #f9fafb;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #afbaca;
  border-radius: 0;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
}
