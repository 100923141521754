.custom-select {
  display: flex;
}
.custom-select .ant-select-selector {
  width: 100%;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: #f9fafb !important;
}
.custom-select .ant-select-selector-search {
  width: 100%;
  height: 100%;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  background: #f9fafb !important;
}
