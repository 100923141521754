.user-form {
  .form-group {
    display: flex;
    align-items: start;
    margin-bottom: 30px;
    width: 100%;

    .label-group {
      height: 46px;
      display: flex;
      align-items: center;
    }

    .content-group {
      flex: 1;
    }

    .ant-form-item {
      margin: 0;
    }
  }

  .ant-form-item-required {
    &::before {
      display: inline-block;
      margin-inline-end: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }
}

@media (max-width: 600px) {
  .user-form {
    .form-group {
      align-items: center;
    }

    .ant-form-item-label,
    .ant-form-item-required {
      width: auto;
      margin-right: 15px;
      height: auto;
    }
  }
}
