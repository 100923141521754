@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  color: #455468;
  font-family: "Inter", sans-serif;
}

.ant-table-wrapper * {
  border-color: #e9eff7 !important;
}

.ant-btn-default {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d7dfe9;
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
  &.with-primary-border {
    border: 1px solid #4a72ff;
    padding: 12px;
    span {
      color: #1b4dff;
    }
  }
}

.ant-btn-primary {
  background: #1b4dff !important;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  gap: 10px;
  height: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;
  box-shadow: none;

  &.ant-btn-dangerous {
    background-color: #e92215 !important;
  }

  span {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
  }
}

.ant-form-item-required::before {
  order: 2;
  margin-left: 6px;
}

.ant-form-item-required::after {
  display: none;
}
.ant-form-item-label > label::after {
  display: none;
}

.ant-btn-loading-icon {
  svg {
    fill: #ffffff;
  }
}

@mixin centerlized {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.ant-select {
  @include centerlized();
}

.ant-form-item-required {
  @include centerlized();
}

.ant-form-item-label {
  @include centerlized();
}

.ant-select-selector {
  background-color: transparent !important;
  border: none !important;

  &:hover,
  &:focus,
  &:active {
    outline: unset !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.ant-select,
.ant-picker,
.ant-select.ant-select-in-form-item,
.ant-input,
.custom-select {
  box-sizing: border-box;
  height: 46px;
  background: #f9fafb;
  border-bottom: 1px solid #afbaca;
  border-top: unset;
  border-left: unset;
  border-right: unset;
  width: 100%;
  border-radius: 0;
}

.ant-select.ant-tree-select {
  min-height: 46px;
  height: unset;
}

.search-wrapper {
  .input-search {
    height: unset;
    border: none;
    padding: 0;
  }
}

.ant-form-item-label > label {
  margin: 0;
}

.ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}

.ant-form-item-explain {
  margin-bottom: 0;
  position: absolute;
}

.ant-form-item-control {
  > div {
    &:nth-child(2) {
      height: 0;
    }
  }
}

@media (max-width: 600px) {
  .ant-form-item-control > div:nth-child(2) {
    height: auto;
  }
  .ant-form-item-explain {
    margin-bottom: 0;
    position: static;
  }
}
